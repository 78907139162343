import React, { useState, createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../components/UserPool';

const AccountContext = createContext();

const Account = (props) => {
    // shared session between all children of the Context
    const [currentSession, setCurrentSession] = useState(undefined);

    // get the current session of the user, containing accessToken, idToken and refreshToken
    // also this functions automatically refreshes the accessToken if it expired
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();

            if (user){
                user.getSession((err, session) => {
                    if (err) {
                        localStorage.removeItem('userSetupComplete');
                        reject(err);
                    }else{
                        // setting the shared state varibale between all the children of the context to the current session
                        // retrieved from Cognito
                        // console.log(session);
                        setCurrentSession(session);
                        resolve(session);
                    }
                })
            }else{
                localStorage.removeItem('userSetupComplete');
                reject('User not logged in.');
            }
        })
    }
    
    // authenticate the user with Username and Password 
    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });

            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    resolve(data);

                },
                onFailure: (err) => {
                    console.error("onFailure: ", err)
                    reject(err);
                    
                },
                newPasswordRequired: (data) => {
                    // console.log("newPasswordRequired: ", data);
                    resolve(data);
                }
            });
        })
    }
    
    // Sign out current user
    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user){
            localStorage.removeItem('userSetupComplete');
            user.signOut();
            setCurrentSession(undefined);
        }
    }

    return (
        <AccountContext.Provider value={ { authenticate, getSession, logout, currentSession, setCurrentSession} }>
            {props.children}
        </AccountContext.Provider>
    )

}


export { Account, AccountContext };