import Config from '../config/Config';


export const get_tutor_description = async (tutor, axiosPrivate) => {
    // gets the links to the files that were uploaded by the tutor applying for the post. 

    // resume is required, rest of files are first checked if they exist before getting there link.
    let tutor_file_links = {};
    const get_download_url =  Config.backendURL +  Config.s3Endpoint + '/download_url/'+ tutor.username;
    try{
        const resume_response = await axiosPrivate.get(get_download_url + '/Resume/resume');
        tutor_file_links["resume"] = `${resume_response.data['url']}`;
        if (tutor.uploaded_cover_letter){
            const letter_response = await axiosPrivate.get(get_download_url + '/CoverLetter/letter');
            tutor_file_links["cover_letter"] = `${letter_response.data['url']}`;
        }
        if (tutor.uploaded_additional_file){
            const file_response = await axiosPrivate.get(get_download_url + '/Additional/file');
            tutor_file_links["additional_file"] =`${file_response.data['url']}`;
        }
        return tutor_file_links;
    }
    catch (err) {
        console.error(err);
        return;
    }
}