import React, { useState, useRef } from 'react'
import { Col, Container, Form, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Config from '../../config/Config';
import PopupModal from "../../components/PopupModal";
import { useNavigate } from "react-router-dom";
import side_form from "../../assets/images/side-form-1.png";


function FindTutorForm({ setShowModal }) {
  const today = new Date().toISOString().split('T')[0];

  const [interest, setInterest] = useState('');
  const [isMultipleSessions, setisMultipleSessions] = useState(false);
  const [numberSessions, setNumberSessions] = useState(1);
  const [course, setCourse] = useState('');
  const [price, setPrice] = useState('');
  const [location, setLocation] = useState('');
  const [online, setOnline] = useState('');
  const [isOnlineSessions, setisOnlineSessions] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrMsg(''); 
    setSubmitted(true);
    setLoading(true);

    if (!interest || !course || !price || !location || !online || !startDate) {
      setErrMsg("Please fill in all required fields");
      errRef.current.focus();
    } else {
      const create_post_url = Config.backendURL + Config.postsEndpoint;

      const form_data = {
        "course_name": course,
        "price": price,
        "number_of_sessions": numberSessions,
        "start_date": startDate,
        "location": location,
        "country": "LEB",
        "additional_info": importantNotes,
        "online_sessions": isOnlineSessions
      }

      axiosPrivate.post(create_post_url, form_data)
        .then(() => {
          setLoading(false);
          setShowModal(true);
        })
        .catch((error) => console.error(error));
    }
  }

  const handleChangeInterest = (event) => {
    setInterest(event.target.value);
    if (event.target.value === "multiple_sessions") {
      setisMultipleSessions(true);
    } else {
      setisMultipleSessions(false);
    }
  }

  const handleChangeOnline = (event) => {
    setOnline(event.target.value);
    if (event.target.value === "Yes") {
      setisOnlineSessions(true);
    } else {
      setisOnlineSessions(false);
    }
  }

  const interest_options = [
    { value: 'multiple_sessions', label: 'Multiple Sessions' },
    { value: 'one_session', label: 'One Session / Project' },

  ];

  const online_options = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <p ref={errRef} className={errMsg ? "ezy__form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="dark" />
          <p>Loading...</p>
        </div>
      ) :
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-interestedIn" className="mb-2">Interested In</Form.Label>
              <Form.Select
                id="findtutor-interestedIn"
                value={interest}
                onChange={handleChangeInterest}
                isInvalid={submitted && !interest}
              >
                <option value="">Please select</option>
                {interest_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {
            isMultipleSessions === true && <Col lg={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="findtutor-nbrSessions">Number of Sessions</Form.Label>
                <Form.Control
                  type="number"
                  id="findtutor-nbrSessions"
                  value={numberSessions}
                  placeholder="1"
                  onChange={(event) => setNumberSessions(event.target.value)}
                  min="1"
                  isInvalid={submitted && !numberSessions}
                />
              </Form.Group>
            </Col>
          }
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-price">Price Willing To Pay ($)</Form.Label>
              <Form.Control
                id="findtutor-price"
                type="number"
                value={price}
                placeholder="$"
                onChange={(event) => setPrice(event.target.value)}
                min="1"
                isInvalid={submitted && !price}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-course">Course</Form.Label>
              <Form.Control
                id="findtutor-course"
                type="text"
                value={course}
                placeholder="Course Name"
                onChange={(event) => setCourse(event.target.value)}
                isInvalid={submitted && !course}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-location">Location</Form.Label>
              <Form.Control
                id="findtutor-location"
                type="text"
                value={location}
                placeholder="Location"
                onChange={(event) => setLocation(event.target.value)}
                isInvalid={submitted && !location}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-online" className="mb-2">Online</Form.Label>
              <Form.Select
                id="findtutor-online"
                value={online}
                onChange={handleChangeOnline}
                isInvalid={submitted && !online}
              >
                <option value="">Please select</option>
                {online_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-date">Start Date</Form.Label>
              <Form.Control
                id="findtutor-date"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                min={today}
                isInvalid={submitted && !startDate}
              />
            </Form.Group>
          </Col>

          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-notes">Important Notes</Form.Label>
              <Form.Control
                id="findtutor-notes"
                as="textarea"
                value={importantNotes}
                placeholder="Relevant notes for this request"
                onChange={(event) => setImportantNotes(event.target.value)}
                isInvalid={submitted && !importantNotes}
              />
            </Form.Group>
          </Col>
        </Row>
      }
      <button
        variant=""
        type="submit"
        className="ezy__form-btn-submit w-100"
        disabled={!interest.trim() || !price.trim() || !course.trim() || !online.trim() || !location.trim() || !startDate.trim() ? true : false}
      >
        Submit
      </button>
    </Form>
  )
}

const FindTutorFormCard = ({ setShowModal }) => {
  return (
    <div className="ezy__form-form-card">
      <div className="p-0">
        <h2 className="ezy__form-heading mb-4 mb-md-5">Find Your Tutor</h2>
        <FindTutorForm
          setShowModal={setShowModal}
        />
      </div>
    </div>
  )
};



function FindTutor({ }) {
  /* Pop Up Section when form is successfully submitted */

  const nextSteps = [
    "We will post your request on our Instagram Page.",
    "Interested tutors will apply through our website.",
    "We will filter the top 5 candidates.",
    "We will send you an instagram message with a link.",
    "You can pick the tutor that perfectly fits your needs."
  ];

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(`/students/home`);
  }

  const popupInformation = {
    title: 'Successful Submit',
    body: `Thank you for using our services! We will get back to you as soon as we process your information!🌟\n You can check all your requests in the "Manage Your Posts" section. \n Next Steps:\n` +
      nextSteps.map((step, index) => `${index + 1}. ${step}`).join('\n'),
    btn_background: Config.purple_color
  };

  return (
    <section className="ezy__form light d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col lg={5}>
            <div
              className="ezy__form-bg-holder d-none d-lg-block h-100"
              style={{ backgroundImage: `url(${side_form})` }}
            />
          </Col>
          <Col lg={6} className="py-5">
            <Row className="align-items-center h-100">
              <Col xs={12}>
                <FindTutorFormCard
                  setShowModal={setShowModal}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PopupModal
        show={showModal}
        onHide={handleCloseModal}
        additionalInfo={popupInformation}
      />
    </section>
  )

}


export default FindTutor;
