import React from 'react';

function TableHeader(props) {
  const { columns, handleSort } = props;

  return (
    <tr>
      {columns.map((column) => (
        <th key={column} onClick={() => handleSort(column)}>
          {column.toUpperCase()}
        </th>
      ))}
      <th>ACTIONS</th>
      {props.viewTutorDetails && (
         <th>DETAILS</th>
      )}
    </tr>
  );
}

export default TableHeader;