import React, { useState } from "react";
import {
	Button,
	Card,
	Carousel,
	Col,
	Container,
	Row,
} from "react-bootstrap";
import '../assets/css/cards.css';
import { useNavigate } from "react-router-dom"; 


const Cards = ({ cards }) => {
	const [index, setIndex] = useState(0);
	const navigate = useNavigate();

	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};

	const handleViewAll = (navigate_to) => {
		navigate(`/${navigate_to.toLowerCase()}`);
	};

	return (
		<div className="ezy__header30-cards">
			<Container>
				<Row className="mt-5">
					<Carousel
						activeIndex={index}
						onSelect={handleSelect}
						controls={false}
						indicators={false}
						slide={false}
						wrap={false}
					>
						{cards.map((cardsList, i) => (
							<Carousel.Item key={i}>
								<Row style={{ justifyContent: 'center' }}>
									{cardsList.map((card, i) => (
										<Col xs={12} md={4} key={i}>
											<Card className="border-0 mt-4 mt-lg-0">
												<Card.Header className="position-relative p-0 border-0">
													<img
														className="card-img-top"
														src={card.photo}
														alt=""
													/>
													<div className="ezy__header30-card-content">
														<p>{card.description}</p>
														<h4>{card.label}</h4>
														<Button
															variant=""
															className="ezy__header30-btn mt-3"
															onClick={() => handleViewAll(card.navigate_to)}
														>
															{card.button_text}
														</Button>
													</div>
												</Card.Header>
											</Card>
										</Col>
									))}
								</Row>
							</Carousel.Item>
						))}
					</Carousel>
				</Row>
			</Container>
		</div>
	);
};


const CardsComponent = ({ title, description, cards, bg_img }) => {
	return (
		<section className="ezy__header30 light">
			<div
				className="ezy__header30-topbar"
				style={{
					backgroundImage:
					`url(${bg_img})`,
				}}
			>
				<div className="ezy__header30-overlay" />
				<Container>
					<Row className="justify-content-center text-center">
						<Col xs={12}>
							<h1 className="ezy__header30-heading mb-4">
								{title}
							</h1>
							<Row className="justify-content-center">
								<Col xs={12} lg={5} className="text-center">
									<p className="ezy__header30-sub-heading">
										{description}
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>

			<Cards cards={cards}/>
		</section>
	);
};


export default CardsComponent;