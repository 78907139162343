import React, { useState } from "react";
import {
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import Config from '../../config/Config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import purple_background from "../../assets/images/purple-background.png";

const Posts = ({ posts, setPostId, setIsPostSelected, setTutors, setLoading }) => {
    const axiosPrivate = useAxiosPrivate();

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleSelectPost = async (post_id) => {
        // client selected a post to see all the tutors that applied to it
        setLoading(true);

        setPostId(post_id);

        // get the list of tutors that applied for this post and have a status of ACCEPTED (tutors accepted by the admin user)
        const get_tutors_by_post_id_url = Config.backendURL + Config.tutorApplicationEndpoint + "/posts/" + post_id + "?current_status=ACCEPTED";
        try {
            const response = await axiosPrivate.get(get_tutors_by_post_id_url);
            const tutorsData = [];
            // get each tutor details 
            await Promise.all(response.data.map(async (tutor_application) => {
                const get_tutor_user_url = Config.backendURL + Config.usersEndpoint + "/" + tutor_application.tutor_username;
                const response_tutors = await axiosPrivate.get(get_tutor_user_url);
                tutorsData.push(response_tutors.data);
            }));

            // Split the tutors data into chunks of 3
            const chunkedTutors = [];
            for (let i = 0; i < tutorsData.length; i += 3) {
                chunkedTutors.push(tutorsData.slice(i, i + 3));
            }

            // Set the state with chunked tutors data
            setTutors(chunkedTutors);
            // change the boolean so that the page renders the tutor selection layout
            setIsPostSelected(true);
        }
        catch (error) {
            console.error(error);
        }finally{
            setLoading(false);
        }
    };

    return (
        <div className="ezy__header30-cards">
            <Container>
                <Row className="mt-5">
                    <Carousel
                        // activeIndex={index}
                        onSelect={handleSelect}
                        controls={true}
                        indicators={false}
                        slide={false}
                        interval={null}
                    >
                        {
                            posts.length === 0 ? (
                                <Carousel.Item>
                                    <Row className={`d-flex justify-content-center align-items-center`}>
                                            <Col xs={12} md={4}>
                                                <Card className="border-0 mt-4 mt-lg-0">
                                                    <Card.Header className="position-relative p-0 border-0">
                                                        <img
                                                            className="card-img-top"
                                                            src={purple_background}
                                                            alt=""
                                                        />
                                                        <div className="ezy__header30-card-content">
                                                            <h4>No posts published yet.</h4>
                                                        </div>
                                                    </Card.Header>
                                                </Card>
                                            </Col>
                                    </Row>
                                </Carousel.Item>
                            ) :
                            (
                                posts.map((postList, i) => (
                                    <Carousel.Item key={i}>
                                        <Row className={`d-flex justify-content-center align-items-center`}>
                                            {postList.map((post, i) => (
                                                <Col xs={12} md={4} key={i}>
                                                    <Card className="border-0 mt-4 mt-lg-0">
                                                        <Card.Header className="position-relative p-0 border-0">
                                                            <img
                                                                className="card-img-top"
                                                                src={purple_background}
                                                                alt=""
                                                            />
                                                            <div className="ezy__header30-card-content">
                                                                <h4>Post ID: {post.post_id}</h4>
                                                                <p>Course: {post.course_name}</p>
                                                                <p>Start Date: {post.start_date}</p>
                                                                <Button
                                                                    variant=""
                                                                    className="ezy__header30-btn mt-3"
                                                                    onClick={() => handleSelectPost(post.post_id)}
                                                                >
                                                                    Select Post
                                                                </Button>
                                                            </div>
                                                        </Card.Header>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Carousel.Item>
                                ))
                            )
                        }
                    </Carousel>
                </Row>
            </Container>
        </div>
    );
};

export default Posts;