import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import '../assets/css/navbar.css';
import Config from '../config/Config';



function Navbar() {
    const { currentSession, logout, getSession } = useAuth();

    let navigate = useNavigate();
    const location = useLocation();

    const [isStudentMode, setIsStudentMode] = useState(true);


    useEffect(() => {
        const checkSession = async () => {
            try {
                await getSession();
            } catch (error) {
                console.warn(error);
            }
        };
        // Define the paths that should trigger a rerender
        const pathsToRerender = ["/students/home", "/tutors/home", "/"];
        // Only run `checkSession` if the current path matches one of the specified paths
        if (pathsToRerender.includes(location.pathname)) {
            checkSession();
        }
    }, [location.pathname]);

    const handleLogoutClick = () => {
        logout();
        navigate('/');
    }

    const getBackgroundColor = () => {
        switch (location.pathname) {
            case "/students/home":
            case "/find/tutor":
            case "/select/tutor":
                return Config.purple_color;
            default:
                return Config.green_color;
        }
    };

    useEffect(() => {
        if (location.pathname.includes("/students")) {
            setIsStudentMode(true);
        } else if (location.pathname.includes("/tutors")) {
            setIsStudentMode(false);
        }
    }, [location.pathname]);

    const handleToggleMode = () => {
        navigate(isStudentMode ? "/tutors/home" : "/students/home");
    };

    const isLandingPage = location.pathname === "/";
    const userSetupComplete = localStorage.getItem('userSetupComplete');

    return (
        <header style={{ background: getBackgroundColor(), position: isLandingPage ? "fixed" : "top" }}>
            <nav className="navbar">
                <h2 className="logo"><a className="navbar-link" href="/">Find Your Tutor</a></h2>
                <input type="checkbox" id="menu-toggler" />
                <label htmlFor="menu-toggler" id="hamburger-btn">
                    &#9776; {/* Unicode for burger menu icon */}
                </label>
                <ul className="all-links">
                    {!currentSession && userSetupComplete === null && (
                        <>
                            <li>{<a className="navbar-link" href="/#steps">How it Works</a>}</li>
                            <li>{<a className="navbar-link" href="/#about">About Us</a>}</li>
                            <li>{<a className="navbar-link" href="/#get-started">Get Started</a>}</li>
                            <li>{<a className="navbar-link" href="/#testimonials">Testimonials</a>}</li>
                            <li>{<a className="navbar-link" href="/#contact">Contact</a>}</li>
                            <li>{<a className="navbar-link" href="/login">Login</a>}</li>
                            <li>{<a className="navbar-link" href="/register">Register</a>}</li>
                        </>
                    )
                    }
                    {currentSession && userSetupComplete && (
                        <>
                            <li>
                                <a className="navbar-link" onClick={handleToggleMode}>
                                    Go To {isStudentMode ? "Tutor" : "Student"} Mode
                                </a>
                            </li>
                            <li>{<a className="navbar-link" onClick={handleLogoutClick}>Logout</a>}</li>
                        </>
                    )
                    }
                </ul>
            </nav>
        </header>
    );
}

export default Navbar;
