import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Config from '../config/Config';



function TableRow({ itemsToShow, headers, isPostTable, onRowClick }) {
  const [action, setAction] = useState('');
  const axiosPrivate = useAxiosPrivate()

  const post_action_options = [
    { value: "POSTED", label: "POSTED" },
    { value: "SUCCEEDED", label: "SUCCEEDED" },
    { value: "FAILED", label: "FAILED" }
  ]

  const tutor_application_action_options = [
    { value: "ACCEPTED", label: "ACCEPTED" },
    { value: "REJECTED", label: "REJECTED" }

  ]
  const handleActionClick = (event, item) => {

    const update_post_url = Config.backendURL + Config.postsEndpoint + "/" + item.post_id;
    axiosPrivate.put(update_post_url,
      {
        "current_status": event.target.value
      })
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }

  const handleTutorActionClick = (event, item) => {

    const update_tutor_application_url = Config.backendURL + Config.tutorApplicationEndpoint + "/" + item.tutor_application_id + "?current_status=" + event.target.value;

    // console.log(update_tutor_application_url);

    axiosPrivate.put(update_tutor_application_url)
      .then((response) => {
        // console.log(response);
        window.location.reload();
      })
      .catch((error) => console.error(error));
  }



  return (
    <tbody>
      {itemsToShow.map((item, index) => (
        <tr key={index}>
          {headers.map((header) => (
            <td key={header}>
              {typeof item[header] === 'boolean' ? (item[header] ? 'Yes' : 'No') : item[header]}
            </td>
          ))}

          {isPostTable ?
            (
              <td>
                <select className='table-select' name={"actions"} id={"actions"} value={action} onChange={(event) => { handleActionClick(event, item) }}>
                  <option value="">Change Status</option>
                  {post_action_options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </td>
            ) :
            (
              <>
                <td>
                  <select className='table-select' name={"actions"} id={"actions"} value={action} onChange={(event) => { handleTutorActionClick(event, item) }}>
                    <option value="">Change Status</option>
                    {tutor_application_action_options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <button className='table-details-button' onClick={() => onRowClick(item)}>View</button>
                </td>
              </>
            )}
        </tr>
      ))}
    </tbody>
  );
}

export default TableRow;
