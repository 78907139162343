import React, { useState } from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import "../../assets/fontawesome/css/all.min.css";
import guy_testimonial_img from "../../assets/images/landing-images/guy-testimonial.png";
import girl_testimonial_img from "../../assets/images/landing-images/girl-testimonial.png";



const testimonialList = [
	{
		photo: guy_testimonial_img,
		name: "Chris",
		position: "University Student",
		from: "Lebanon",
		content:
			"This service made it super easy to find a tutor who really understood what I needed. I was matched with a tutor who had expertise in calculus, and their personalized approach made a huge difference. Plus, it was all within the budget I proposed. The process was quick, and the results were amazing—I'm doing better than I ever expected in my course!",
	},
	{
		photo: girl_testimonial_img,
		name: "Louna",
		position: "Grade 11",
		from: "Lebanon",
		content:
			"Finding a tutor through this website was a game-changer for me! I was struggling with physics and chemistry, but the platform matched me with a tutor who explained everything in a way that made sense. I loved how easy it was to find someone who fit my learning style, schedule, and budget. My grades have improved, and I feel so much more confident in class!"
	},
];

const Testimonial19 = () => {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex) => setIndex(selectedIndex);

	const handleControl = (type) => {
		if (type === "prev") {
			setIndex(index <= 0 ? testimonialList.length - 1 : index - 1);
		} else {
			setIndex(index >= testimonialList.length - 1 ? 0 : index + 1);
		}
	};

	return (
		<section className="ezy__testimonial19 light">
            <h2>Testimonials</h2>
            <p>Our customers love us.</p>
			<Container>
				<Row className="justify-content-center align-items-center position-relative">
					<Col md={6} className="position-relative">
						<div className="ezy__testimonial19-card">
							<Carousel
								activeIndex={index}
								onSelect={handleSelect}
								controls={false}
								indicators={false}
							>
								{testimonialList.map(({ name, content, position, from }, i) => (
									<Carousel.Item key={i}>
										<div>
											<p className="mb-4">{content}</p>
											<h5 className="ezy__testimonial19-author">
												- {name}, {position}, {from}
											</h5>
										</div>
									</Carousel.Item>
								))}
							</Carousel>
						</div>
					</Col>
					<Col md={4} className="text-center text-md-start mt-5 mt-md-0">
						<Carousel
							activeIndex={index}
							onSelect={handleSelect}
							controls={false}
							indicators={false}
						>
							{testimonialList.map(({ photo, name }, i) => (
								<Carousel.Item key={i}>
									<img src={photo} alt={name} />
								</Carousel.Item>
							))}
						</Carousel>
					</Col>

					<Button
						variant=""
						className="carousel-control-prev"
						onClick={() => handleControl("prev")}
					>
						<i className="fas fa-solid fa-angle-left"></i>
					</Button>
					<Button
						variant=""
						className="carousel-control-next"
						onClick={() => handleControl("next")}
					>
						<i className="fas fa-solid fa-angle-right"></i>
					</Button>
				</Row>
			</Container>
		</section>
	);
};

export default Testimonial19;