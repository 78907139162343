import React, { Fragment, useRef, useState } from "react";
import CodeVerification from '../../components/CodeVerification';
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import Config from '../../config/Config';

import { Col, Container, Form, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import '../../assets/css/image-form-overlapping.css';
import google_img from "../../assets/images/google.png";
import side_form from "../../assets/images/side-form-1.png";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';


function userBelongsToGroup(userGroups, groupName) {
    return userGroups && userGroups.includes(groupName);
}

const SocialLoginButton = () => (
    <Fragment>
        <button
            variant="danger"
            className="ezy__overlapping_form-btn w-100 d-flex align-items-center"
        >
            <img src={google_img} alt="" className="google-img" />
            <span className="w-100 text-center">Continue with Google</span>
        </button>
    </Fragment>
);

const SignInForm = ({ setVerificationProcess, email, setEmail }) => {
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [loading, setLoading] = useState(false);


    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);

    // get authenticate function from the Account Context
    const { logout, authenticate } = useAuth();

    // logic added if user tried to access a path and was not logged in.
    // he will be redirected to login and after successfully logging in, will be redirected to his desired page
    // or to students homepage
    let navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/students/home";

    const axiosPrivate = useAxiosPrivate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrMsg('');
        setSubmitted(true); // Mark that the form has been submitted

        if (email && password) {
            setValidated(true);
            setLoading(true);
            try {
                // authenticate the user with email and password
                const data = await authenticate(email, password);
                // if the user is logging in for the first time, add his username to the backend table 
                await createBackendUser(data);
                // After createBackendUser completes, mark setup as complete for denying access to private routes
                localStorage.setItem('userSetupComplete', 'true');
                // check if user belongs to a certain group
                const groupsAttr = data?.idToken?.payload?.['cognito:groups'];
                // verify if he belongs to the admin user group
                const isAdmin = userBelongsToGroup(groupsAttr, 'admin');
                // navigate to admin page if user is admin, else navigate to from page
                navigate(isAdmin ? '/admin' : from, { replace: true });

            } catch (err) {
                // if the user did not verify his email account, the verification process will be displayed
                if (err.name === 'UserNotConfirmedException') {
                    setVerificationProcess(true);
                }
                else {
                    setPassword('');
                    setErrMsg(err.message);
                    errRef.current.focus();
                }
            } finally {
                setLoading(false);
            }
        }
    }

    async function createBackendUser(data) {
        // this function is called on first login of user
        const config = {
            headers: {
                Authorization: `Bearer ${data?.accessToken?.jwtToken}`,
            },
        };
        try {
            // check if the user already exists
            await axiosPrivate.get(Config.backendURL + Config.usersEndpoint, config);
        }
        catch (err) {
            if (err.response) {
                if (err.response.status === 400 && err.response.data?.detail?.includes("User not found")) {
                    await axiosPrivate.post(Config.backendURL + Config.usersEndpoint, null, config);
                } else {
                    logout();
                    setErrMsg('System is not accessible. Please try again later.');
                    errRef.current.focus();
                    throw err;
                }
            } else {
                logout();
                throw new Error('System is not accessible. Please try again later.');
            }
        }
    }

    const handleUsernameChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleForgotPassword = () => {
        navigate('/forget/password');
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <p ref={errRef} className={errMsg ? "ezy__overlapping_form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                    <p>Loading...</p>
                </div>
            ) :
                <>
                    <Form.Group className="mb-4 mt-2">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" placeholder="Your Email Address" value={email} onChange={handleUsernameChange} isInvalid={submitted && !email} />
                    </Form.Group>
                    <Form.Group className="mb-2 mt-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Password" value={password} onChange={handlePasswordChange} isInvalid={submitted && !password} />
                    </Form.Group>

                    <button
                        variant=""
                        type="submit"
                        className="ezy__overlapping_form-btn-submit w-100"
                    >
                        Log In
                    </button>
                    <button variant="" type="button" className="ezy__overlapping_form-btn-link w-100" onClick={handleForgotPassword}>
                        Forget your password?
                    </button>
                </>
            }

        </Form>
    );
};

const SignInFormCard = ({ setVerificationProcess, email, setEmail }) => {
    let navigate = useNavigate();

    const handleCreateAccount = () => {
        navigate('/register');
    };

    return (
        <div className="ezy__overlapping_form-form-card">
            <div className="p-md-5">
                <h2 className="ezy__overlapping_form-heading mb-3">Welcome</h2>
                <p className="mb-4 mb-md-5">
                    <span className="mb-0 opacity-50 lh-1">Don't have an account?</span>
                    <button variant="link" className="ezy__overlapping_form-btn-link py-0 text-decoration-none" onClick={handleCreateAccount}>
                        Create account
                    </button>
                </p>

                <SignInForm
                    setVerificationProcess={setVerificationProcess}
                    email={email}
                    setEmail={setEmail}
                />

                <div className="position-relative ezy__overlapping_form-or-separator">
                    <hr className="my-4 my-md-5" />
                    <span className="px-2">Or</span>
                </div>

                <SocialLoginButton />
            </div>
        </div>
    )
};

const Login = () => {
    const [verificationProcess, setVerificationProcess] = useState(false);
    const [email, setEmail] = useState('');

    //called by the CodeVerification component (child component) when the verification process succeeds
    const handleVerificationProcess = (value) => {
        setVerificationProcess(value);
    }

    return (
        <>
            {verificationProcess ? (
                <CodeVerification onValueChange={handleVerificationProcess} email={email} page={"Login"} />
            ) : (
                <section className="ezy__overlapping_form light d-flex">
                    <Container>
                        <Row className="justify-content-between h-100">
                            <Col md={4} lg={6}>
                                <div
                                    className="ezy__overlapping_form-bg-holder d-none d-md-block h-100"
                                    style={{ backgroundImage: `url(${side_form})` }}
                                />
                            </Col>
                            <Col md={8} lg={6} className="py-5">
                                <Row className="align-items-center h-100 px-xl-5">
                                    <Col xs={12}>
                                        <SignInFormCard
                                            setVerificationProcess={setVerificationProcess}
                                            email={email}
                                            setEmail={setEmail}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
            }
        </>
    );
};

export default Login;