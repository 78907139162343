import axios from "axios";
import Config from '../config/Config';

export default axios.create({
    baseURL:  Config.backendURL
});

export const axiosPrivate = axios.create({
    baseURL:  Config.backendURL,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
});