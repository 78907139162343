import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RedirectIfAuthenticated = ({ children }) => {
  const { getSession } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        setIsLoading(true);
        const response = await getSession();
        setCurrentSession(response);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, []);

  if (isLoading) {
    // Optionally show a loading spinner if session is being checked
    return <div className="loading">Loading...</div>;
  }

  // If the user is already authenticated, redirect them to /students/home
  if (currentSession) {
    return <Navigate to="/students/home" replace />;
  }

  // If not authenticated, render the login page content
  return children;
};

export default RedirectIfAuthenticated;
