import configData from "./production_config.json";

class Config {
  constructor() {
    this.userPoolId = process.env.NODE_ENV === 'production'
      ? configData.COGNITO_POOL_ID
      : process.env.REACT_APP_COGNITO_POOL_ID;

    this.clientId = process.env.NODE_ENV === 'production'
      ? configData.COGNITO_CLIENT_ID
      : process.env.REACT_APP_COGNITO_CLIENT_ID;

    this.backendURL = process.env.NODE_ENV === 'production'
      ? configData.BACKEND_URL
      : process.env.REACT_APP_BACKEND_URL;

    this.postsEndpoint= "api/posts"
    this.usersEndpoint= "api/users"
    this.tutorApplicationEndpoint= "api/tutor_applications"
    this.s3Endpoint= "api/s3"
    this.purple_color = "#70448c";
    this.green_color = "#1e4d63";
  }
}

const configInstance = new Config();

export default configInstance;