import { useState, useEffect } from "react";

import Chart from '../../components/BarChart';
import Table from '../../components/Table';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Config from '../../config/Config';
import '../../assets/css/admin.css';



function Admin() {
  const [postsData, setPostsData] = useState([]);
  const [tutorApplicationsData, setTutorApplicationsData] = useState([]);
  const axiosPrivate = useAxiosPrivate();


  const get_posts_url =  Config.backendURL +  Config.postsEndpoint + "?country=LEB";
  const get_tutor_applications_url =  Config.backendURL +  Config.tutorApplicationEndpoint;

  const count_posts_by_status = (postsData, status) => {
    const filteredPosts = postsData.filter(post => post.current_status === status);
    return filteredPosts.length;
  }

  const count_tutor_applications = (status) => {
    if (status === "") {
      return tutorApplicationsData.length;
    }
    const filteredApplications = tutorApplicationsData.filter(application => application.current_status === status);
    return filteredApplications.length;
  }

  const activePostsCount = count_posts_by_status(postsData, 'NEW') +
                        count_posts_by_status(postsData, 'POSTED');

  const fulfilledPostsCount = count_posts_by_status(postsData, 'FULFILLED');

  const succeededPostsCount = count_posts_by_status(postsData, 'SUCCEEDED');

  const failedPostsCount = count_posts_by_status(postsData, 'FAILED');

  const tutorApplicationsCount = count_tutor_applications(''); 

  const acceptedTutorApplicationsCount = count_tutor_applications('ACCEPTED'); 
  
  const rejectedTutorApplicationsCount = count_tutor_applications('REJECTED'); 

  
  const ongoing_posts_data = [
    { name: "Active Posts", value: activePostsCount},
    { name: "Fulfilled Posts", value: fulfilledPostsCount}
  ]

  const posts_data = [
    { name: "Succeeded Posts", value: succeededPostsCount},
    { name: "Failed Posts", value: failedPostsCount }
  ]

  const tutor_applications_data = [
    { name: "Tutor Appl.", value: tutorApplicationsCount},
    { name: "Accepted", value: acceptedTutorApplicationsCount},
    { name: "Rejected", value: rejectedTutorApplicationsCount},

  ]

  useEffect( () => {
    let isMounted = true;
    const controller = new AbortController();
    const get_posts = async() =>{
      try{
        const response = await axiosPrivate.get(get_posts_url, {
          signal: controller.signal
          });
          setPostsData(response.data);
      }catch (err) {
        console.error(err);
    }
    }
    const get_tutor_applications = async () => { 
      try{
        const response = await axiosPrivate.get(get_tutor_applications_url, {
          signal: controller.signal
          });
          setTutorApplicationsData(response.data);
      }catch (err) {
        console.error(err);
      }
    }
    get_posts();
    get_tutor_applications();
    
    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);


  return (
    <div className='admin'>
      <h1>Admin Control Center</h1>
      <div className='charts'>
        <Chart data={ongoing_posts_data}/>
        <Chart data={tutor_applications_data}/>
        <Chart data={posts_data}/>
      </div>
      <Table 
      url={get_posts_url} 
      tableHeaders={['post_id', 'course_name', 'current_status', 'country', 'location', 'number_of_sessions', 'online_sessions', 'additional_info', 'price', 'start_date']}
      tableName ="Request Details"
      isPostTable = {true}
      viewTutorDetails = {false}
      />
      <Table 
      url={get_tutor_applications_url} 
      tableHeaders={['post_id', 'current_status', 'additional_info']}
      tableName ="Tutor Applications"
      isPostTable = {false}
      viewTutorDetails = {true}
      />
    </div>
    )
}

export default Admin;
