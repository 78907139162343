import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../../assets/css/manage-applications.css';
import Config from '../../config/Config';
import PopupModal from "../../components/PopupModal";


const ApplicationCard = ({ application, onCancelClick }) => {

    return (
        <Card className="ezy__applications1-card border-0 my-2">
            <Card.Body className="d-flex flex-column flex-md-row align-items-center justify-content-between p-3 p-md-4">
                <h5 className="ezy__applications1-title mb-2 mb-md-0">{application.post.course_name}</h5>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="ezy__applications1-time d-flex align-items-center my-1 mx-2 mx-sm-3">
                        <p className="ezy__applications1-details mb-0">Post ID: {application.post_id}</p>
                    </div>
                    <div className="ezy__applications1-time d-flex align-items-center my-1 mx-2 mx-sm-3">
                        <p className="ezy__applications1-details mb-0">Start Date: {application.post.start_date}</p>
                    </div>
                    <div className="ezy__applications1-location d-flex align-items-center my-1 mx-2 mx-sm-3">
                        <p className="ezy__applications1-details mb-0">{application.current_status}</p>
                    </div>
                    <Button
                        variant="primary"
                        className="px-3 py-1 py-md-2 my-1 mx-2 mx-sm-3"
                        onClick={() => onCancelClick(application.post_id)}
                    >
                        Cancel Application
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};


const Applications = () => {
    const axiosPrivate = useAxiosPrivate();

    const [applications, setApplications] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        // load all applications that correspond to the current user
        const loadApplications = async () => {
            setLoading(true);
            const get_tutor_applications_url = Config.backendURL + Config.tutorApplicationEndpoint + "/my_applications";
            try {
                const response = await axiosPrivate.get(get_tutor_applications_url);
                const applicationsData = response.data;

                // For each application, fetch the associated post data
                const enrichedApplications = await Promise.all(
                    applicationsData.map(async (application) => {
                        const postUrl = Config.backendURL + Config.postsEndpoint + `/${application.post_id}`;

                        try {
                            const postResponse = await axiosPrivate.get(postUrl);
                            // Merging the application with the corresponding post data
                            return { ...application, post: postResponse.data };
                        } catch (error) {
                            console.error(`Error fetching post data for post_id: ${application.post_id}`, error);
                            return { ...application, post: null }; // Handle errors and return the application without post data
                        }
                    })
                );
                setApplications(enrichedApplications);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                setLoading(false);
            }
        }
        loadApplications();
    }, [])

    const handleCancelClick = (applicationId) => {
        setSelectedApplicationId(applicationId);
        setShowModal(true);
    };

    const handleConfirmCancel = async (applicationId) => {
        try {
            setLoading(true);
            const cancel_tutor_application_url = `${Config.backendURL}${Config.tutorApplicationEndpoint}/${applicationId}`;
            await axiosPrivate.delete(cancel_tutor_application_url);
            setApplications((prevApplications) =>
                prevApplications.filter((application) => application.tutor_application_id !== applicationId)
            );
        } catch (error) {
            console.error('Error cancelling application', error);
        }finally{
            setLoading(false);
        }
    };

    const handleCloseModal = () => setShowModal(false);

    const popupInformation = {
        title: 'Confirm Cancellation',
        body: 'Are you sure you want to cancel this application?',
        application_id: selectedApplicationId,
        btn_background: Config.green_color
    }

    return (
        <section className="ezy__applications1 light">
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12} md={8} className="text-center">
                        <h1 className="ezy__applications1-heading mb-3">Manage your Applications</h1>
                        <p className="ezy__applications1-sub-heading">
                            Monitor the status of your applications and stay informed about the status of each of your applications.
                        </p>
                    </Col>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" variant="dark" />
                            <p>Loading...</p>
                        </div>
                    ) :
                        <Col xs={12} className="mt-5">
                            {applications.map((application, i) => (
                                <ApplicationCard application={application} key={i} onCancelClick={() => handleCancelClick(application.tutor_application_id)} />
                            ))}
                        </Col>
                    }
                </Row>
            </Container>
            <PopupModal
                show={showModal}
                onHide={handleCloseModal}
                onConfirm={handleConfirmCancel}
                additionalInfo={popupInformation}
            />
        </section>
    );
};


export default Applications;